@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto+Slab|Roboto:400,700&display=swap');

html {
    font-size: 16px;
    font-family: Roboto, sans-serif;
}

@media (max-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    html {
        font-size: 14px;
    }
}

body {
  background-color: black;
  font-family: Roboto, sans-serif;
}

.App {
  text-align: center;
}

a {
  color: #333;
}

footer a {
  color: #37C1FF;
  text-decoration: none;
}

footer a:hover {
  color: #55D2FF;
}



/* Navigation */

.Nav {
  z-index: 99999;
}

.Nav circle {
  fill: white;
  stroke: #999999;
  stroke-width: .4rem;
  cursor: pointer;
}

.Nav .nav-active circle {
  fill: #C4C4C4;
}
